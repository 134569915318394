#midi {
  display: flex;
  /* flex-direction: row; */
  /* box-sizing: content-box; */
  position: relative;
  grid-template:
    'labels' 'midi' / 100px 1000px;
  white-space: nowrap;
  width: 100%;
  justify-content: center;
}

#track-position {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  grid-column: 1;
  grid-row: 1;
  width: 2px;
  height: 100%;
  border-right: solid 3px black;
  transform-style: preserve-3d;
}

  #midi-note-labels {
    display: grid;
    margin-left: auto;
    grid-column: 1;
    grid-row: 1;
    position: sticky;
    left: 0;
    width: 100%;
    z-index: 3;
    margin-top: 21px;
  }

  #midi-track {
    display: grid;
    grid-area: 1 / 2;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    position: relative;
    background-color: rgb(161, 161, 161);
    /* width: 100%; */
    margin-right: auto;
  } 
    #subdivs {
      display: grid;
      grid-column: 1;
      grid-row: 1;
    }
      .subdivision {
        border-left: solid .08em rgb(114, 114, 114);
      }

    #note-tracks {
      display: grid;
      grid-column: 1;
      grid-row: 1;
      margin-top: 21px;
      /* width: 85em; */
    }
      .note-track {
        outline: rgb(114, 114, 114) solid .08em;
        position: relative;
      }

#time-slider {
  width: 100%;
  background-color: var(--main-color);
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  appearance: none;
  background: linear-gradient(transparent 40%,black 40%, black 60%, transparent 60%, transparent 100%);
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  visibility: hidden;
  background-color: black;
  height: 1rem;
  width:1rem;
  border-radius: 2px;
}