#key-note-input {
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-color: transparent;
    /* z-index: -1; */
    transform: translateZ(-10px);
}
#key-note-input:focus {
    outline: none;
}