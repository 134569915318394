  /* #track-position {
    display: inline;
    grid-area: 1 / 2;
    position: sticky;
    top: 0;
    width: 3px;
    border-right: solid 3px black;
    z-index: 2;
  } */

#bpm-slider {
  position: relative;
  height: 20px;
  z-index: 0;
}

.note-label {
  border: none;
  outline: black inset 2px;
  padding: 6px 6px;
  display: block;
  text-align: start;
  width: 100%;
  height: 8.33%;
}
  .note-label:hover {
    background-color: gray;
  }
  .note-label.natural {
    background-color: white;
    color: black;
  }
  .note-label.accidental {
    background-color: black;
    color: white;
  }
  .note-label.active {
    background-color: rgb(246, 34, 34);
    color: white;
  }
