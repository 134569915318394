.App {
  text-align: center;
  background-color: var(--main-color);
  height: max-content;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin: auto;
}

#welcome-user {
  padding: 0 0 3.5px 2px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  cursor: default;
  border-radius: inherit;
}

.login-elems {
  display: block;
  position: relative;
  float:right;
  border-radius: 4px;
  margin: 0 2vw;
  /* float: left; */
}

.button.loginout {
  margin-top: 1px;
  border-radius: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#info-button {
  float: left;
  margin: 0;
  border: none;
  background: none;
  font-size: 24px;
}

@keyframes drop-in-info{
  0% {top: -100%; position: absolute;}
  100% {top: 25vh; position: fixed;}
}

#popup-info {
  display: flex;
  /* position: fixed; */
  justify-self: center;
  align-self: center;
  flex-direction: column;
  /* top: 6.5vh;
  left: 12.5vw; */
  height: fit-content;
  width: 75%;
  /* justify-content: center; */
  align-items: center;
  padding: 2em;
  background-color: var(--main-color);
  z-index: 11;
  border-radius: 20px;
  animation-name: drop-in-info;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.info-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.info-text {
  display: block;
  font-size: medium;
  margin: 0;
  text-align: left;
}

.keyboard {
  display: flex;
  flex-direction: column;
}

.keyboard .top-row {
  display: inherit;
  justify-content: center;
}

.keyboard .bottom-row {
  display: inherit;
}

.info .keyboard .key {
  display: inline-flex;
  height: 5rem;
  width: 5rem;
  background: white;
  border-radius: 10px;
  font-size: 1em;
  align-items: center;
  justify-content: center;
}

.info .keyboard .key.hidden {
  visibility: hidden;
}

button.info {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background: none;
}

button.info.close {
  font-size: 1.5em;
}

.exit-button {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background-color: transparent;
}

#small-screen-msg {
  display: none;
}

@media screen and (max-width: 950px) {
  .info .keyboard .key {
    width: 3rem;
    height: 3rem;
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  #selectors, #midi {
    display: none;
  }

  #small-screen-msg {
    display: block;
  }

  #popup-info {
    display: none;
  }

  #popup-bg {
    display: none;
  }
}